import chartExportionOptionsMixin from "./chartExportionOptionsMixin";

export default {
  mixins: [chartExportionOptionsMixin],
  data: () => ({}),
  methods: {
    getPieChartOptions(seriesData, options = { isPercent: false }) {
      const percent_text = options.isPercent ? " %" : "";

      return {
        chart: {
          type: "pie",
          height: 250,
        },
        exporting: this.chartExportingOptions,
        plotOptions: {
          pie: {
            allowPointSelect: true,
            cursor: "pointer",
            dataLabels: {
              enabled: true,
              distance: 30,
              formatter: function() {
                return `${this.point.name}: ${parseFloat(
                  this.point.y
                ).toLocaleString("pt-BR")}${percent_text}`;
                // return parseFloat(this.point.y).toLocaleString('pt-BR')
              },
            },
            showInLegend: true,
          },
        },
        credits: { enabled: false },
        title: { text: null },
        subtitle: { text: null },
        legend: { enabled: false },
        lang: {
          noData: "Sem dados para exibição",
        },
        series: {
          name: "Valor",
          colorByPoint: true,
          innerSize: "75%",
          animation: false,
          data: seriesData,
        },
      };
    },
  },
};
