<template>
  <span>
    <highcharts :options="chartOptions" :deepCopyOnUpdate="false" />
  </span>
</template>

<script>
import pieChartMixin from './pieChartMixin';

  export default {
    name: "ChartPrpPorCanal",
    mixins: [pieChartMixin],
    props: {
      dadosPorCanal: {
        type: Object,
        required: true,
      }
    },
    data: () => ({
      chartOptions: {},
    }),
    methods: {
      setChartOptions() {
        this.chartOptions = this.getPieChartOptions(
          this.getChartSeriesData(),
          {
            isPercent: true
          }
        );
      },
      getChartSeriesData() {
        let seriesData = [];

        for (const canal in this.dadosPorCanal) {
          seriesData.push({
            name: canal,
            y: this.dadosPorCanal[canal].prp,
          });
        }

        return seriesData;
      },
    },
    watch: {
      dadosPorCanal() {
        this.setChartOptions();
      }
    }
  };
</script>

<style></style>
